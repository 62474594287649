<template>
  <div class="row cham-cong">
    <div class="col-md-12 col-xl-12 align-items-center">
      <div class="row">
        <div class="col-lg-12 col-xl-2 order-lg-1 order-xl-1">
          <!-- profile summary -->
          <div class="card mb-g rounded-top">
            <div class="d-flex flex-row pb-3 pt-4 text-left pl-2 border-top-0 border-left-0 border-right-0">
              <h4 class="mb-0 flex-1 text-dark fw-500">Thời gian </h4>
            </div>
            <div class="col-md-10">
              <el-date-picker
                  class="w-100"
                  v-model="form.selectDate"
                  type="month"
                  placeholder="Chọn tháng"
                  @change="search"
              >
              </el-date-picker>
            </div>
            <div class="row no-gutters row-grid">
              <div class="col-12 p-4">
                <h5 class="mb-3  col-xs-6">
                  <!-- <span class="d-inline-block width-size">Họ và tên: </span><span class="fw-700">{{ currentUser.name || (currentUser.user && currentUser.user.name) }}</span>
              </h5>
              <h5 class="mb-3 col-xs-6">
                  <span class="d-inline-block width-size">Mã nhân sự:</span><span class="fw-700">{{  (currentUser.id || currentUser.user.id) + 100000  }}</span>
              </h5>
              <h5 class="mb-3 col-xs-6">
                  <span class="d-inline-block width-size">Văn Phòng: </span><span class="fw-700">{{  office }}</span> -->
                </h5>
              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-12 col-xl-10 order-lg-3 order-xl-2">
          <div class="panel">
            <div class="panel-container show">
              <div class="panel-content">
                <table class="report-table">
                  <thead>
                  <tr class="bg-violet text-white ">
                    <th class="report-row">Mục</th>
                    <th class="report-row">Nội dung</th>
                    <th class="report-row">Số tiền</th>
                    <th class="report-row">Phần trăm</th>
                    <th class="report-row">Nhận xét</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="!checkData" class="report-row">
                    <td colspan="5" class="text-center mt-4 report-cell">Chưa có thông tin báo cáo của tháng
                      {{ form.selectDate }}
                    </td>
                  </tr>
                  <tr v-else v-for="(report, index) in reports" :key="index" class="report-row">
                    <td class="mt-4 report-cell " :class="'pheight-' + index">
                      <span class="bold"
                            :class="report.reportName === 'BÁO CÁO TÀI CHÍNH' ? 'text-violet pointer ' : ''"
                            @click="changeHandleModel(report.reportName)" style="cursor:pointer">{{report.reportName}}</span>
                    </td>
                    <td class="report-cell">
                      <ul>
                        <li :style="{height: (item.maxDescriptionLength) + 'px'}" v-for="(item, i) in report.items"
                            :key="i" @click="handleActive(item.key, item.details ?? [], item.total_details ?? 0)"
                            class="mt-4 "
                            :class="item.bool ? 'bold' : ''">
                            <span :class="item.details && item.key !== 'tai_chinh_van_phong_thang_nay' ? 'text-violet pointer ' : ''">{{ item.title }}</span>
                        </li>
                      </ul>
                    </td>
                    <td class="report-cell">
                      <ul>
                        <li :style="{height: (item.maxDescriptionLength) + 'px'}" v-for="(item, i) in report.items"
                            :key="i" class="mt-4" :class="changeClass(index)">
                          <span :class="item.bool ? 'bold' : ''">{{
                              typeof item.money === 'number' ? formatVND(item.money) : item.money
                            }}</span>
                        </li>
                      </ul>
                    </td>
                    <td class="report-cell">
                      <ul>
                        <li :style="{height: (item.maxDescriptionLength) + 'px'}" v-for="(item, i) in report.items"
                            :key="i" class="mt-4" :class="changeClass(index)">
                          <span :class="item.bool ? 'bold' : ''">{{ typeof item.percent === 'number' ? item.percent + '%' : item.percent }} </span>
                        </li>
                      </ul>
                    </td>
                    <td class="report-cell">
                      <ul>
                        <li :style="{height: (item.maxDescriptionLength) + 'px'}" v-for="(item, i) in report.items"
                            :key="i" class="mt-4" :class="changeClass(index)">
                          <span :class="item.bool ? 'bold' : ''" v-if="item.description">{{ item.description }}</span>
                          <span v-else>&nbsp;</span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div>
          <el-dialog
              :title="title"
              :visible.sync="dialogReport"
              width="70%"
              :before-close="handleClose">
            <modal-financial-report v-if="index == 0" :data="detailsFinancialReport"></modal-financial-report>
            <modal-financial-actual-contribute v-if="index == 3" :totalDetails="totalDetails"
                                               :data="detailsActualContributed"></modal-financial-actual-contribute>
            <modal-office-expense-report v-if="index == 1" :totalDetails="totalDetails"
                                         :data="detailsOfficeReport"></modal-office-expense-report>
            <modal-financial-contribute v-if="index == 2" :totalDetails="totalDetails"
                                        :data="detailsShareholderCapital"></modal-financial-contribute>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ModalOfficeExpenseReport from "./compoment/ModalOfficeExpenseReport.vue";
import ModalFinancialReport from "./compoment/ModalFinancialReport.vue"
import ModalFinancialContribute from "./compoment/ModalFinancialContribute.vue"
import ModalFinancialActualContribute from "./compoment/ModalFinancialActualContribute.vue"
import {
  GET_REPORT_FINANCE_GDVP,
  CATE_CONTENT_NAME_OFFICE_REPORTS
} from "../../../core/services/store/finances/finances.module"
import {formatVND} from "../../../core/filters";

export default {
  name: 'OfficeReport',
  components: {
    ModalOfficeExpenseReport,
    ModalFinancialReport,
    ModalFinancialContribute,
    ModalFinancialActualContribute
  },
  data() {
    return {
      item: {},
      categorys: CATE_CONTENT_NAME_OFFICE_REPORTS,
      pickerStartOptions: {
        disabledDate: this.disabledStartDate
      },
      fromDate: null,
      form: {
        selectDate: null
      },
      title: null,
      index: null,
      dialogReport: false,
      reportNames: {
        bao_cao_kinh_doanh: 'BÁO CÁO KINH DOANH',
        bao_cao_tai_chinh: 'BÁO CÁO TÀI CHÍNH',
        thu_nhap_ca_nhan_trong_thang: 'THU NHẬP CÁ NHÂN TRONG THÁNG',
        von_gop_co_dpng: 'VỐN GÓP CỔ ĐÔNG',
      },
      checkData: false,
      reports: [
        {
          reportName: 'BÁO CÁO KINH DOANH',
          items: [
            {
              title: "I. Tổng doanh thu",
              key: "tong_doanh_thu",
              bool: true,
              money: " ",
              percent: ' ',
              description: ''
            },
            {
              title: "1. Doanh thu đội kinh doanh",
              key: "doanh_thu_doi_kinh_doanh",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "2. Doanh thu digital marketing",
              key: "doanh_thu_digital_marketing",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "II. TỔNG CHI PHÍ",
              key: "tong_chi_phi",
              bool: true,
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "1. Chi lương kinh doanh",
              key: "chi_luong_kinh_doanh",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "2. Chi sản phẩm",
              key: "chi_san_pham",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "3. Chi backoffice",
              key: "chi_backoffice",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "4. Chi phúc lợi",
              key: "chi_phuc_loi",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "5. Chi lương HCKV",
              key: "chi_luong_hckv",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "6. Chi văn phòng",
              key: "chi_van_phong",
              value: " ",
              details: [],
              percent: ' ',
              description: ' '
            },
            {
              title: "7. Chi khác",
              key: "chi_khac",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "III. LỢI NHUẬN",
              bool: true,
              key: "loi_nhuan",
              value: " ",
              percent: ' ',
              description: ' '
            },
          ]
        },
        {
          reportName: 'BÁO CÁO TÀI CHÍNH',
          items: [
            {
              title: "1. Tài chính văn phòng tháng trước",
              key: "tai_chinh_van_phong_thang_truoc",
              value: " ",
              percent: '',
              description: ''
            },
            {
              title: "2. Tài chính văn phòng tháng này",
              key: "tai_chinh_van_phong_thang_nay",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "3. Phần trăm cổ phần",
              key: "phan_tram_co_phan",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "4. Giá trị tương ứng phần trăm cổ phần tại văn phòng",
              key: "gia_tri_tuong_ung_phan_tram_co_phan_tai_van_phong",
              value: " ",
              percent: ' ',
              description: ' '
            },
          ]
        },
        {
          reportName: 'THU NHẬP CÁ NHÂN TRONG THÁNG',
          items: [
            {
              title: "1. Chi cổ tức tháng",
              key: "chi_co_tuc_thang",
              value: " ",
              percent: ' ',
              description: ' '
            },
            {
              title: "2. Giá trị cổ tức tăng lên tháng này",
              key: "gia_tri_co_tuc_tang_len_thang_nay",
              value: " ",
              percent: ' ',
              description: ' '
            },
          ]
        },
        {
          reportName: 'VỐN GÓP CỔ ĐÔNG',
          items: [
            {
              title: "1. Vốn góp cổ đông",
              key: "von_gop_co_dong",
              value: " ",
              details: [],
              percent: ' ',
              description: ' '
            },
            {
              title: "2. Vốn góp thực tế",
              key: "von_gop_thuc_te",
              value: " ",
              details: [],
              percent: ' ',
              description: ' '
            },
            {
              title: "3. Vốn góp bổ sung",
              key: "von_gop_bo_sung",
              value: " ",
              percent: ' ',
              description: ' '
            },
          ]
        },
      ],
      detailsOfficeReport: {},
      detailsFinancialReport: {},
      detailsShareholderCapital: {},
      detailsActualContributed: {},
      totalDetails: 0
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Danh sách"},
      {title: "Báo cáo văn phòng", icon: 'far fa-money-bill'}
    ]);
    let lastMonth = moment().format('YYYY-MM-DD');
    this.form.selectDate = new Date(lastMonth);
    this.getData();
  },
  methods: {
    formatVND,
    pickStart(date) {
      this.fromDate = null;
      if (date) {
        this.fromDate = new Date(date);
      }
    },
    handleClose() {
      this.dialogReport = false
    },
    handleActive(index, details, total_details) {
      this.totalDetails = total_details;
      let key = '';
      let text = '';
      switch (index) {
        case 'tai_chinh_van_phong_thang_nay':
          key = 0;
          this.detailsFinancialReport = details;
          text = 'Báo cáo tài chính';
          this.dialogReport = true
          break;
        case 'chi_van_phong':
          key = 1;
          text = 'Báo cáo chi văn phòng tháng ' + (moment(this.form.selectDate).month() + 1)
          this.detailsOfficeReport = details;
          this.dialogReport = true
          break;
        case 'von_gop_co_dong':
          key = 2;
          this.detailsShareholderCapital = details;
          text = 'Vốn góp cổ đông';
          this.dialogReport = true
          break;
        case 'von_gop_thuc_te':
          key = 3;
          this.detailsActualContributed = details;
          text = 'Vốn góp thực tế';
          this.dialogReport = true
          break;
      }
      this.index = key;
      this.title = text;
    },
    getData() {
      this.form.selectDate = moment(String(this.form.selectDate)).format('YYYY-MM');

      this.$store.dispatch(GET_REPORT_FINANCE_GDVP, {month: this.form.selectDate}).then((res) => {
        let dataApi = res.data;
        if (!dataApi) {
          this.reports = [];
          return;
        }
        this.reports = this.reports.map(item => {
          // Lọc dataApi để chỉ giữ các phần tử có key trùng với item.items
          const filteredItems = dataApi.filter(c => item.items.find(d => d.key === c.key));
          // Tạo ra một mảng mới từ item.items, thêm các thuộc tính còn thiếu với giá trị mặc định nếu cần
          const updatedItems = item.items.map(d => {
            const matchingItem = filteredItems.find(c => c.key === d.key);
            if (matchingItem) {
              return {
                ...matchingItem,
                bool: d.bool, // Giữ lại giá trị của `bool` từ `item.items`
                title: d.title // Giữ lại giá trị của `bool` từ `item.items`
              };
            } else {
              return {
                key: d.key,
                money: d.money || ' ',
                percent: d.percent || ' ',
                description: d.description || ' ',
                bool: d.bool // Giữ lại giá trị của `bool` từ `item.items`
              };
            }
          });

          return {
            ...item,
            items: updatedItems
          };
        });

        this.reports.forEach(report => {
          let maxDescriptionLength = 20;
          report.items.forEach(item => {
            if (item.description && item.description.length > maxDescriptionLength) {
              maxDescriptionLength = item.description.length - 145;
              maxDescriptionLength = item.description.length - 130;
            }
            item.maxDescriptionLength = maxDescriptionLength;
            maxDescriptionLength = 20;
          });
        });
        this.checkData = true;
      }).catch((response) => {
        this.checkData = false;
      });
    },
    search() {
      this.form.selectDate = moment(String(this.form.selectDate)).format('YYYY-MM-DD');
      this.getData();
    },
    changeClass(index) {
      return 'height-dom-' + index;
    },
    changeHandleModel(name) {
      if (name === 'BÁO CÁO TÀI CHÍNH') {
        this.reports.map(item => {
          if (item.items.find(d => d.key === 'tai_chinh_van_phong_thang_nay')) {
            this.item = item.items.find(d => d.key === 'tai_chinh_van_phong_thang_nay');
          }
        });
        this.handleActive(this.item.key, this.item.details, this.item.total_details ?? [])
        return;
      }
    }
  }
}
</script>
<style>
.bg-violet {
  background-color: #472F92;
}

ul {
  list-style-type: none;
}

/*li {*/
/*    border-bottom: 1px solid #e5dada;*/
/*}*/
.text-violet {
  color: #8830bd;
}

.report-cell {
  padding: 8px;
  border: 1px solid #ddd;
  word-break: break-word;
  vertical-align: top;
}

.report-cell ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.report-cell ul li {
  margin-bottom: 4px;
}

.bold {
  font-weight: bold;
}

.text-violet {
  color: #8320e5;
}

/* Điều chỉnh độ rộng của các cột */
.report-cell:nth-child(1) {
  width: 20%; /* Độ rộng của cột 1 */
}

.report-cell:nth-child(2) {
  width: 25%; /* Độ rộng của cột 2 */

}

.report-cell:nth-child(3) {
  width: 10%; /* Độ rộng của cột 3 */
}

.report-cell:nth-child(4) {
  width: 15%; /* Độ rộng của cột 4 */

}

.report-cell:nth-child(5) {
  width: 30%; /* Độ rộng của cột 5 */
}

.pointer {
  cursor: pointer;
}

.pheight-0 {
  padding-top: 20%;
}

.pheight-1 {
  padding-top: 5%;
}

.pheight-2 {
  padding-top: 3%;
}

.pheight-3 {
  padding-top: 4%;
}

th {
  padding: 10px;
}
</style>
